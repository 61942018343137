import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Stack,
  useDisclosure,
  useToast,
  Text,
  Switch,
  Heading,
  SimpleGrid,
  StackDivider
} from "@chakra-ui/react";
import { FaPlus, FaTimes } from "react-icons/fa";
import dayjs from "dayjs";
import { useCreatePatientMutation } from "../api";
import { useTypedDispatch } from "hooks/use-typed-dispatch.hook";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/chakra-react-datepicker.css";
import { Select } from "chakra-react-select";
import { divisions } from "utils/bbs";
import { genderList } from "../constants/gender.constant";
import { registerNewPatient } from "../state/patient.slice";
import { useTypedSelector } from "hooks/use-typed-selector.hook";

interface RegisterPatientComponentProps {
  phoneNumber: string;
}

export const RegisterPatientComponent: React.FC<
  RegisterPatientComponentProps
> = (props: RegisterPatientComponentProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useTypedDispatch();
  const patientState = useTypedSelector((state) => state.patient);

  const toast = useToast();

  const [createPatient, createPatientResult] = useCreatePatientMutation();

  const [name, setName] = useState<string>("");

  const [addressLine, setAddressLine] = useState<string>();

  const [nationalIdNumber, setNationalIdNumber] = useState<string>();
  const [passportNumber, setPassportNumber] = useState<string>();
  const [healthIdNumber, setHealthIdNumber] = useState<string>();
  const [religion, setReligion] = useState<string>();
  const [birthCertificate, setBirthCertificate] = useState<string>();
  const [occupation, setOccupation] = useState<string>();
  const [nationality, setNationality] = useState<string>();

  const [gender, setGender] = useState<{
    label: string;
    value: string;
  }>();

  const [division, setDivision] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [district, setDistrict] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [subdistrict, setSubdistrict] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  const [union, setUnion] = useState<{
    label: string;
    code: number;
    value: number;
  }>();

  // Show default as 25 years old
  const [dateOfBirth, setDateOfBirth] = useState<Date | null>(null);

  const [isDateOfBirthEstimated, setIsDateOfBirthEstimated] =
    useState<boolean>(false);

  const [age, setAge] = useState<number | null>(null);

  const handlePatientRegistration = () => {
    createPatient({
      data: {
        otp: patientState.otp || "",
        contactNumber: patientState.phoneNumber || "",
        person: {
          name,
          contactNumber: props.phoneNumber,
          dateOfBirth,
          fatherName: null,
          motherName: null,
          imageUrl: null,
          isDateOfBirthEstimated,
          gender: gender?.value as "male" | "female",
          dateOfDeath: null,
          isDateOfDeathEstimated: null
        },
        patient: {
          patientCategoryName: "General"
        },
        identifiers: [
          ...(religion
            ? [{ identifierTypeName: "Religion", identifierValue: religion }]
            : []),
          ...(nationality
            ? [
                {
                  identifierTypeName: "Nationality",
                  identifierValue: nationality
                }
              ]
            : []),
          ...(healthIdNumber
            ? [
                {
                  identifierTypeName: "Health Identification Number",
                  identifierValue: healthIdNumber
                }
              ]
            : []),
          ...(birthCertificate
            ? [
                {
                  identifierTypeName: "Birth Certificate Number",
                  identifierValue: birthCertificate
                }
              ]
            : []),
          ...(nationalIdNumber
            ? [
                {
                  identifierTypeName: "National Identification Number",
                  identifierValue: nationalIdNumber
                }
              ]
            : []),
          ...(occupation
            ? [
                {
                  identifierTypeName: "Occupation",
                  identifierValue: occupation
                }
              ]
            : []),
          ...(passportNumber
            ? [
                {
                  identifierTypeName: "Passport Number",
                  identifierValue: passportNumber
                }
              ]
            : [])
        ],
        address: {
          addressLine1: addressLine ? addressLine : null,
          addressLine2: null,
          postalCode: null,
          divisionCode: division ? division.code : null,
          districtCode: district ? district.code : null,
          subdistrictCode: subdistrict ? subdistrict.code : null,
          unionCode: union ? union.code : null,
          addressTypeName: "Present Address"
        }
      }
    });
  };

  useEffect(() => {
    if (createPatientResult.isSuccess) {
      toast({
        title: "Success",
        description: "Patient registered successfully",
        status: "success",
        position: "top",
        duration: 2000,
        isClosable: true
      });

      dispatch(
        registerNewPatient({
          patientWithDetails: createPatientResult.data.patientWithDetails
        })
      );
      onClose();
    }
  }, [createPatientResult.isSuccess]);

  // If age is edited. change the date of birth
  useEffect(() => {
    if (age) {
      if (age !== dayjs().diff(dayjs(dateOfBirth), "year")) {
        setDateOfBirth(dayjs().subtract(age, "year").toDate());
      }
    }
  }, [age]);

  // If date of birth is updated, update the age accordingly (only year)
  useEffect(() => {
    if (dateOfBirth) {
      if (age !== dayjs().diff(dayjs(dateOfBirth), "year")) {
        setAge(dayjs().diff(dayjs(dateOfBirth), "year"));
      }
    }
  }, [dateOfBirth]);

  return (
    <>
      <Button colorScheme={"blue"} leftIcon={<FaPlus />} onClick={onOpen}>
        Register New Patient (Phone: {props.phoneNumber})
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "lg", lg: "5xl" }}
        closeOnOverlayClick={false}
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Patient Registration</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing="4" divider={<StackDivider />}>
              <Stack spacing="4">
                <Heading fontSize="md">Patient Details</Heading>
                <SimpleGrid
                  columns={{ base: 1, xl: 2 }}
                  rowGap={2}
                  columnGap={2}
                >
                  <FormControl isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired isReadOnly>
                    <FormLabel>Phone Number</FormLabel>
                    <Input value={props.phoneNumber} />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid
                  columns={{ base: 1, xl: 3 }}
                  rowGap={2}
                  columnGap={2}
                >
                  <FormControl isRequired>
                    <FormLabel>Gender</FormLabel>
                    <Select
                      selectedOptionStyle="check"
                      options={genderList}
                      value={gender}
                      onChange={(event) => {
                        if (event) {
                          setGender({
                            label: event.label,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Date Of Birth</FormLabel>

                    <DatePicker
                      selected={dateOfBirth}
                      onChange={(date) => date && setDateOfBirth(date)}
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      <Stack direction="row" spacing="2" alignItems={"center"}>
                        <Text>Estimated Age</Text>{" "}
                        <Switch
                          checked={isDateOfBirthEstimated}
                          onChange={() => {
                            setIsDateOfBirthEstimated(!isDateOfBirthEstimated);
                          }}
                        ></Switch>
                      </Stack>
                    </FormLabel>
                    <Input
                      placeholder="Select date of birth"
                      value={age || 0}
                      type="number"
                      min="0"
                      step="1"
                      onChange={(event) => {
                        setAge(event.target.valueAsNumber);
                      }}
                      disabled={!isDateOfBirthEstimated}
                    />
                  </FormControl>
                </SimpleGrid>
              </Stack>
              <Stack spacing="4">
                <Heading fontSize="md">Identification</Heading>
                <SimpleGrid
                  columns={{ base: 1, xl: 2 }}
                  rowGap={2}
                  columnGap={2}
                >
                  <FormControl>
                    <FormLabel>National Identification (NID) Number</FormLabel>
                    <Input
                      placeholder="Enter NID Number (Optional)"
                      value={nationalIdNumber}
                      onChange={(event) => {
                        setNationalIdNumber(event.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Birth Certificate Number</FormLabel>
                    <Input
                      placeholder="Enter Birth Certificate (Optional)"
                      value={birthCertificate}
                      onChange={(event) => {
                        setBirthCertificate(event.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Health Identification Number</FormLabel>
                    <Input
                      placeholder="Enter Health ID (Optional)"
                      value={healthIdNumber}
                      onChange={(event) => {
                        setHealthIdNumber(event.target.value);
                      }}
                      disabled
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Religion</FormLabel>
                    <Input
                      placeholder="Enter Religion (Optional)"
                      value={religion}
                      onChange={(event) => {
                        setReligion(event.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Nationality</FormLabel>
                    <Input
                      placeholder="Enter Nationality (Optional)"
                      value={nationality}
                      onChange={(event) => {
                        setNationality(event.target.value);
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Occupation</FormLabel>
                    <Input
                      placeholder="Enter Occupation (Optional)"
                      value={occupation}
                      onChange={(event) => {
                        setOccupation(event.target.value);
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
              </Stack>

              <Stack spacing="4" pb={12}>
                <Heading fontSize="md">Patient Address</Heading>
                <FormControl>
                  <FormLabel>Address Line</FormLabel>
                  <Input
                    placeholder="Enter Address (Optional)"
                    value={addressLine}
                    onChange={(event) => {
                      setAddressLine(event.target.value);
                    }}
                  />
                </FormControl>
                <SimpleGrid
                  columns={{ base: 2, xl: 4 }}
                  rowGap={2}
                  columnGap={2}
                >
                  <FormControl>
                    <FormLabel>Division</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="Division (Optional)"
                      selectedOptionStyle="check"
                      options={divisions.map((division, index) => {
                        return {
                          label: division.divisionName,
                          code: division.divisionCode,
                          value: index
                        };
                      })}
                      value={division}
                      onChange={(event) => {
                        if (event) {
                          setDivision({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>District</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="District (Optional)"
                      selectedOptionStyle="check"
                      options={
                        division
                          ? divisions[division.value].districts.map(
                              (district, index) => {
                                return {
                                  label: district.districtName,
                                  code: district.districtCode,
                                  value: index
                                };
                              }
                            )
                          : []
                      }
                      value={district}
                      onChange={(event) => {
                        if (event) {
                          setDistrict({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Upazilla</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="Upazilla (Optional)"
                      selectedOptionStyle="check"
                      options={
                        division && district
                          ? divisions[division.value].districts[
                              district.value
                            ].subdistricts.map((subdistrict, index) => {
                              return {
                                label: subdistrict.subdistrictName,
                                code: subdistrict.subdistrictCode,
                                value: index
                              };
                            })
                          : []
                      }
                      value={subdistrict}
                      onChange={(event) => {
                        if (event) {
                          setSubdistrict({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Union</FormLabel>
                    <Select
                      menuPlacement="top"
                      placeholder="Union (Optional)"
                      selectedOptionStyle="check"
                      options={
                        division && district && subdistrict
                          ? divisions[division.value].districts[
                              district?.value
                            ].subdistricts[subdistrict.value].unions.map(
                              (union, index) => {
                                return {
                                  label: union.unionName,
                                  code: union.unionCode,
                                  value: index
                                };
                              }
                            )
                          : []
                      }
                      value={union}
                      onChange={(event) => {
                        if (event) {
                          setUnion({
                            label: event.label,
                            code: event.code,
                            value: event.value
                          });
                        }
                      }}
                    />
                  </FormControl>
                </SimpleGrid>
              </Stack>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              leftIcon={<FaTimes />}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              leftIcon={<FaPlus />}
              isLoading={createPatientResult.isLoading}
              onClick={handlePatientRegistration}
              isDisabled={!name || !gender || !dateOfBirth}
            >
              Register
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
