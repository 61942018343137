import React, { useEffect } from "react";
import { Center, Stack, Text } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useValidatePaymentQuery } from "../api";

interface SuccessfulPaymentPageProps {}

export const SuccessfulPaymentPage: React.FC<
  SuccessfulPaymentPageProps
> = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ticketId = searchParams.get("ticketId") as string;

  // Fetch the query parameter from window.location if needed
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("ticketId");

  // Call the hook directly in the component
  const validatePaymentResult = useValidatePaymentQuery({
    transactionId: id || ticketId
  });

  useEffect(() => {
    if (
      validatePaymentResult.isSuccess &&
      validatePaymentResult.data.paymentStatus.ticket.isPaid
    ) {
      navigate(`/ticket/details?ticketId=${id || ticketId}`);
    }
  }, [validatePaymentResult, navigate, id, ticketId]);

  return (
    <Stack>
      <Center height="90vh" width="100vw">
        <Stack width="512px" spacing="12">
          <Text textAlign={"center"} fontSize={"3xl"} color="green.600">
            Ticket Booked Successfully
          </Text>
          <Text textAlign={"center"} fontSize={"xl"} color="gray.600">
            Redirecting to ticket details page...
          </Text>
        </Stack>
      </Center>
    </Stack>
  );
};
